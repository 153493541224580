import './App.css';
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import AuthService from "./services/auth.service";
import EventBus from "./common/EventBus";
import Realization from "./components/Realization";
import Login from "./components/Login";
import ExcelExporter from './components/ExcelExporter';
import EmailSetting from './components/EmailSetting';
import PrivateRoute from './components/PrivateRoute'; // PrivateRoute bileşenini ekle

function App() {
  const [token, setToken] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    
    if (user) {
      setToken(user);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setToken(undefined);
    navigate("/");
  };

  return (
    <>
      <div className='mycss' style={{ height: '100vh' }}>
        <Routes>
          <Route path="/" element={<Login />} />

          {/* role=ADMIN için erişim sağlanan sayfa */}
          <Route
            path="/export"
            element={
              <PrivateRoute roles={['ADMIN']}>
                <ExcelExporter />
              </PrivateRoute>
            }
          />
          
          {/* role=USER ve ADMIN için erişim sağlanan sayfa */}
          <Route
            path="/realization"
            element={
              <PrivateRoute roles={['USER', 'ADMIN']}>
                <Realization />
              </PrivateRoute>
            }
          />

          {/* role=ADMIN için erişim sağlanan sayfa */}
          <Route
            path="/settings"
            element={
              <PrivateRoute roles={['ADMIN']}>
                <EmailSetting />
              </PrivateRoute>
            }
          />
          
          {/* Erişimi olmayan sayfaya yönlendirme */}
          <Route path="/no-access" element={<div>Erişim Yetkiniz Yok</div>} />
        </Routes>
      </div>
    </>
  );
}

export default App;

