import { saveAs } from "file-saver";
import IntegrationService from "./IntegrationService";

const getRoutePlan = (startDate, endDate, planId, page, pageSize) => {

  const params = {
    startDate: startDate,
    endDate: endDate,
    planId: planId,
    page: page,
    pageSize: pageSize
  }

  return IntegrationService.getApi("routePlanReport", params).then((response) => {

    return response.data;

  }).catch(error => {
    console.log(error)
    return error;
  });
}

const getRealizationExcel = (startDate, endDate, planIds) => {

  return IntegrationService.postDownload("excelExportRealization", {
    startDate: startDate,
    endDate: endDate,
    planIds: planIds
  }).then(response => {

    console.log(response.data)

    saveAs(response.data, 'plan' + startDate + '.xlsx');

  }).catch(err => {
    console.log(err)
    return err;
  })

}

const getTelematikVehicleExcelExport = () => {

  return IntegrationService.getDownload("excelExportVehicles", null).then(response => {

    saveAs(response.data, 'telematik_vehicles.xlsx');

  }).catch(err => {
    console.log(err)
    return err;
  })


}


const getTelematikVehicle = () => {

  return IntegrationService.getApi("telematikVehicles", null).then((response) => {

    return response.data;

  }).catch(error => {
    console.log(error)
    return error;
  });
}

const getSensorHistoryExcel = (startDate, endDate, idList) => {

  return IntegrationService.getDownload("excelExportSensorHistory", {
    startDate: startDate,
    endDate: endDate,
    idList: idList
  }).then(response => {

    console.log(response.data)

    saveAs(response.data, 'sensor_history' + startDate + '.xlsx');

  }).catch(err => {
    console.log(err)
    return err;
  })

}

const getTempSensorHistoryExcel = (startDate, endDate, idList, minute) => {

  return IntegrationService.getDownload("excelExportTemperatureSensorHistory", {
    startDate: startDate,
    endDate: endDate,
    idList: idList,
    minute: minute
  }).then(response => {

    console.log(response.data)

    saveAs(response.data, 'temperature_sensor_history' + startDate + '.xlsx');

  }).catch(err => {
    console.log(err)
    return err;
  })

}




const getSensorHistoryLastDepotExcel = (planIdList) => {

  return IntegrationService.getDownload("excelExportSensorHistoryLastDepot", {
    planIdList: planIdList,
  }).then(response => {

    console.log(response.data)

    const currentDate = new Date().toISOString().split('T')[0];

    saveAs(response.data, 'sensor_history_depot_' + currentDate + '.xlsx');

  }).catch(err => {
    console.log(err)
    return err;
  })

}

const getTemperatureSensorAlarmsExcel = (planIdList) => {

  return IntegrationService.postDownload("excelExportTemperatureSensorAlarms", {
    planIdList: planIdList
  }).then(response => {

    console.log(response.data)

    const currentDate = new Date().toISOString().split('T')[0];

    saveAs(response.data, 'temperature_sensor_alarms_' + currentDate + '.xlsx');

  }).catch(err => {
    console.log(err)
    return err;
  })

}

const getSensorAlarmsExcel = (planIdList) => {

  return IntegrationService.postDownload("excelExportSensorAlarms", {
    planIdList: planIdList
  }).then(response => {

    console.log(response.data)

    const currentDate = new Date().toISOString().split('T')[0];

    saveAs(response.data, 'sensor_alarms_' + currentDate + '.xlsx');

  }).catch(err => {
    console.log(err)
    return err;
  })

}

const getPlansWithSensorAndLastDepot = () => {

  return IntegrationService.getApi("plansWithSensorAndLastDepot", null).then((response) => {

    return response.data;

  }).catch(error => {
    console.log(error)
    return error;
  });
}

const reSendStepOrtec = (id, stepAddress, type) => {

  const requestBody = {
    id: id,
    stepAddress: stepAddress,
    type: type
  };


  return IntegrationService.postApi("reSendStepOrtec", requestBody).then((response) => {
   
    return response;

  }).catch(error => {
    if (error.response){

      return error
      //do something
      
      }else if(error.request){
        return error

      //do something else
      
      }else if(error.message){
      
      //do something other than the other two
      
      }
      throw error;
  });

}

const reSendAllStepOrtec = (id) => {

  const requestBody = {
    Id: id
  };

  return IntegrationService.postApi("reSendAllStepOrtec", requestBody).then((response) => {

    return response.data;

  }).catch(error => {
    console.log(error);
    throw error;
  });
}

const reSendPlansOrtec = (ids) => {

  const requestBody = {
    IdList: ids,

  };

  return IntegrationService.postApi("reSendPlansOrtec", requestBody).then((response) => {

    console.log(response.data)

    const message = response.data;

    return message;

  }).catch(error => {
    console.log(error);
    return error;
  });

}

export default {
  getRoutePlan,
  getRealizationExcel,
  getTelematikVehicleExcelExport,
  reSendStepOrtec,
  reSendAllStepOrtec,
  reSendPlansOrtec,
  getSensorHistoryExcel,
  getTempSensorHistoryExcel,
  getTelematikVehicle,

  getSensorHistoryLastDepotExcel,
  getTemperatureSensorAlarmsExcel,
  getSensorAlarmsExcel,
  
  getPlansWithSensorAndLastDepot
}